import "slick-carousel";
import common from "../routes/common";
export default {
  init() {

    jQuery(function () {
      
      var $slider = $('#kioske-slider');
      $slider.slick({
        lazyLoad: "ondemand",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        autoplaySpeed: 2000,
        prevArrow: '#ksq-btn-lft',
        nextArrow: '#ksq-btn-rgt',
        responsive: []
      });

      // Function to show/hide elements based on current slide index
      function updateDisplay(index) {
      // For slideshow images
      $('.kioske-element').each(function () {
        if (parseInt($(this).data('pub-index'), 10) === index) {
        $(this).removeClass('hidden');
        } else {
        $(this).addClass('hidden');
        }
      });
      // For accompanying information (header, paragraph, links)
      $('.ke-header, .ke-p, .ke-liens').each(function () {
        if (parseInt($(this).data('pub-index'), 10) === index) {
        $(this).removeClass('hidden');
        } else {
        $(this).addClass('hidden');
        }
      });
      }

      // On initialization, update display for slide 0
      $slider.on('init', function (event, slick) {
      updateDisplay(0);
      });

      // On slide change, update the elements accordingly
      $slider.on('afterChange', function (event, slick, currentSlide) {
      updateDisplay(currentSlide);
      });

      // Manually trigger the init event (if it hasn't been triggered automatically)
      if ($slider.hasClass('slick-initialized')) {
      updateDisplay($slider.slick('slickCurrentSlide'));
      }

      var commuSlider = $('#commu-element');
      commuSlider.slick({
        lazyLoad: "ondemand",
        slidesToShow: 3.5,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        arrows: true,
        autoplaySpeed: 2000,
        prevArrow: '#com-btn-lft',
        nextArrow: '#com-btn-rgt',
        
        responsive: []
      })


    })


    jQuery(document).ready(() => {
      console.log("home");
      $(".slider-actualites").slick({
        lazyLoad: "ondemand",
        dots: true,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: ".lien-slider",
      });
    });
    
    //un clic toggle responsive
    $("#toggleUnclick").click(function () {
      $("#un-clic__menu").toggleClass("visible");
      $("#toggleUnclick").toggleClass("rotate");
    });

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        "//connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v2.9&appId=142191202511797";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    var windowsize = $(window).width();

    windowsize = $(window).width();
    if (windowsize < 1200) {
      jQuery(".facebook").appendTo(jQuery(".home__top"));
    };
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
